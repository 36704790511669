import React from 'react';
import styled from 'styled-components';
const config = require('../../data.json');

export default function ContentWrapper({
  children,
  maxWidth = config.maxContentWidth,
  bg = '#fff',
}) {
  return (
    <Wrapper maxWidth={maxWidth} style={{backgroundColor: bg}}>
      <Center maxWidth={maxWidth}>{children}</Center>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: 80px;
  background-color: white;

  @media only screen and (max-width: ${(props) => props.maxWidth}px) {
    padding: 20px;
  }
`;

export const Center = styled.div`
  max-width: ${(props) => props.maxWidth || config.maxContentWidth}px;
  margin: 0px auto;
`;
